import { observable, action } from "mobx";
import I18N from "components/i18n/i18n";

export class RootStore {
  @observable page: string = "Обучение";
  @observable os: string = "android";
  @observable history: Array<string> = ["Обучение"];
  @observable languageID: string | null = null;

  @action
  prepareHeader() {
    this.history = [I18N.getString("training")]
    this.page = I18N.getString("training")
  }

  @action
  changePage(page: string) {
    return (this.page = page);
  }

  @action
  changeOS(os: string) {
    return (this.os = os);
  }

  @action
  setLanguage(id: string) {
      this.languageID = id;
  }

  @action
  historyBack() {
    if (this.history.length > 1) {
      this.history.shift();
      return this.history;
    }
    return "No more steps back!";
  }

  @action
  historyAdd(entry: string) {
    this.history.unshift(entry);
    return this.history;
  }
}

let rootStore = new RootStore();
export default rootStore;
