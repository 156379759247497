import React, {Component} from 'react';
import "../styles/LanguageSelect.css";
import rootStore from "../stores/RootStore";
import I18N from "components/i18n/i18n";
import LanguagesModel from "../models/Languages.model";
import { RouteComponentProps } from 'react-router-dom';

interface IState {
    selected: string;
}

interface IProps extends RouteComponentProps{}

class LanguageSelect extends Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            selected: "ru-RU"
        }
    }

    onContinue() {
        const { selected } = this.state;
        const { history, location } = this.props;
        rootStore.changeOS(location.pathname.includes("ios") ? "ios" : "android");
        rootStore.setLanguage(selected);
        rootStore.prepareHeader();
        history.push(location.pathname)
    }


    render() {
        const { selected } = this.state;

        const locale = LanguagesModel.filter((x) => {
            if (rootStore.languageID !== null) {
                return x.id === rootStore.languageID
            }

            return x.id === selected;
        })[0].locale
        return (
            <div className="wrapper">
                <header className="selectHeader"><I18N forceLocale={selected} phrase="selectLanguage"/></header>
                <section className="selectBody">
                    { LanguagesModel.map((item, index) =>
                        (<div key={index} className={`${"selectItem"} ${item.id === selected ? "itemActive" : ""}`} onClick={() => { this.setState({selected: item.id}) }}>
                            <span className="nameNatural">{item.name}</span>
                            <span className="nameLocalized">{item.localized[locale.toString()]}</span>
                        </div>)
                    ) }
                </section>
                <button className="selectConfirm" onClick={() => { this.onContinue() }}>
                    <I18N forceLocale={selected} phrase="continue"/>
                </button>
            </div>
        );
    }
}

export default LanguageSelect;
