import React, {Component} from 'react';
import I18N  from "components/i18n/i18n";
import { useHistory } from "react-router-dom";
import "../../styles/NoTranslation.css";

function NoTranslation() {
        const history = useHistory();

        return (
            <div className="noTranslationWrapper">
                <I18N phrase="noTranslation"/>
            </div>
        );
}

export default NoTranslation;
