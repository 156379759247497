import React, { Component } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import routes from './routes.json';
import rootStore from "stores/RootStore";
import LanguageSelect from "pages/LanguageSelect";
import NoTranslation from "components/i18n/NoTranslation";

export default class CustomRouter extends Component {
    loadComponents(name: string, universal: boolean) {
        const { os } = rootStore;
        if (universal) {
            return React.lazy(() => import(`../../pages/universal/${ name }`));
        }
        return React.lazy(() => import(`../../pages/${os.toLowerCase()}/${ name }`));
    }

    getAllRoutes = () => {
        const language = rootStore.languageID;
        if (language !== null) {
            return(<>{routes.map((item, index)=>{
                {if (item.languages.includes(language)) {
                    if (item.hasOwnProperty("redirect") && item.redirect) {
                        return (<Route key={index} exact path={item.route}>
                            <Redirect to={item.redirect}/>
                        </Route>)
                    } else if (item.hasOwnProperty("component") && item.component) {
                        return (<Route key={index} exact path={item.route} component={this.loadComponents(item.component, item.isUniversal)}/>)
                    }
                } else {
                    return <Route key={index} exact path={item.route}><NoTranslation/></Route>;
                }}
            })}</>)
        } else {
            return <Route component={LanguageSelect}/>
        }
    }

    render() {
        const Component = this.getAllRoutes();
        return (<React.Suspense fallback={"Loading..."}>
            <Switch>{Component} <Route>404</Route></Switch>
        </React.Suspense>)
    }
}
