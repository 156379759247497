import React, {Component} from 'react';
import bengali from "./Locales/bn-BD.json";
import english from "./Locales/en-US.json";
import russian from "./Locales/ru-RU.json";
import rootStore from "../../stores/RootStore";
import languagesModel from "../../models/Languages.model";

interface IProps {
    phrase?: string,
    forceLocale?: string
}

export function getTranslation(phrase: string, forceLocale?: string) {
    const { languageID } = rootStore;

    const ru: Record<string, string> = russian;
    const en: Record<string, string> = english;
    const bn: Record<string, string> = bengali;

    if (phrase) {
        if (forceLocale) {
            switch (forceLocale) {
                case "ru-RU":
                    return ru[phrase];
                case "en-US":
                    return en[phrase];
                case "bn-BD":
                    return bn[phrase];
            }
        }
        if (languageID !== null) {
            switch (languageID) {
                case "ru-RU":
                    return ru[phrase];
                case "en-US":
                    return en[phrase];
                case "bn-BD":
                    return bn[phrase];
            }
        } else {
            return ru[phrase];
        }

    } else {
        return "";
    }
}

class I18N extends Component<IProps> {
    public static getString(phrase: string): string {
        const translated = getTranslation(phrase);

        if (translated) {
            return translated;
        }
        return phrase;
    }

    public static generateLocalizedImageLink(image: string): string {
        const { os, languageID } = rootStore;

        if (image) {
            if (languageID !== "ru-RU") {
                const id = languagesModel.filter(x => x.id === languageID)[0].shortId;
                return `/images/${os.toLowerCase()}/screens/${id}_${image}`;
            }
            return `/images/${os.toLowerCase()}/screens/${image}`;
        }
        return "";
    }

    translate(phrase: string, forceLocale?: string): string {
        const translated = getTranslation(phrase, forceLocale);

        if (translated) {
            return translated;
        }
        return phrase;
    }

    render() {
        const { phrase, forceLocale } = this.props;
        return (
            <span dangerouslySetInnerHTML={{__html: this.translate(`${phrase}`, forceLocale)}}></span>
        );
    }
}

export default I18N;
