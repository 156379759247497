import React from "react";
import "./App.css";
import rootStore from "stores/RootStore";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation, Redirect,
} from "react-router-dom";
// import Index from "pages/ios/Index";
// import AIndex from "pages/android/Index";
// import Settings from "pages/universal/Settings";
// import NightMode from "pages/ios/NightMode";
// import ANightMode from "pages/android/NightMode";
// import FontSize from "pages/ios/FontSize";
// import AFontSize from "pages/android/FontSize";
// import DriverMenu from "pages/universal/DriverMenu";
// import BalanceMenu from "pages/universal/BalanceMenu";
// import ReplenishMenu from "pages/ios/ReplenishMenu";
// import AReplenishMenu from "pages/android/ReplenishMenu";
// import RepSberTerminal from "pages/ios/repSberTerminal";
// import ARepSberTerminal from "pages/android/repSberTerminal";
// import RepSberOnline from "pages/ios/repSberOnline";
// import ARepSberOnline from "pages/android/repSberOnline";
// import ARepQiwi from "pages/android/repQiwi";
// import RepQiwi from "pages/ios/repQiwi";
// import RepCkassa from "pages/ios/repCkassa";
// import ARepCkassa from "pages/android/repCkassa";
// import RepCard from "pages/ios/repCard";
// import ARepCard from "pages/android/repCard";
// import Output from "pages/ios/output";
// import AOutput from "pages/android/output";
// import DriverState from "pages/ios/driverState";
// import ADriverState from "pages/android/driverState";
// import DriverAttribute from "pages/ios/driverAttribute";
// import ADriverAttribute from "pages/android/driverAttribute";
// import DriverSort from "pages/ios/driverSort";
// import ADriverSort from "pages/android/driverSort";
// import DriverPreorder from "pages/ios/driverPreorder";
// import ADriverPreorder from "pages/android/driverPreorder";
// import DriverArmor from "pages/ios/driverArmor";
// import ADriverArmor from "pages/android/driverArmor";
// import ADriverPackages from "pages/android/driverPackages";
// import DriverPackages from "pages/ios/driverPackages";
// import ADriverRating from "pages/android/driverRating";
// import DriverRating from "pages/ios/driverRating";
import chevronBack from "images/ios/chevron-back.svg";
import arrowBack from "images/android/chevron-back.svg";
// import Order from "pages/universal/Order";
// import AOrderSelect from "pages/android/orderSelect";
// import OrderSelect from "pages/ios/orderSelect";
// import AOrderComplete from "pages/android/orderComplete";
// import OrderComplete from "pages/ios/orderComplete";
// import AOrderAuction from "pages/android/orderAuction";
// import OrderAuction from "pages/ios/orderAuction";
// import AOrderRecalc from "pages/android/orderRecalc";
// import OrderRecalc from "pages/ios/orderRecalc";
// import AOrderWaiting from "pages/android/orderWaiting";
// import OrderWaiting from "pages/ios/orderWaiting";
// import AOrderAgreement from "pages/android/orderAgreement";
// import OrderAgreement from "pages/ios/orderAgreement";
// import AOrderNavigation from "pages/android/orderNavigation";
// import OrderNavigation from "pages/ios/orderNavigation";
// import AOrderCommentary from "pages/android/orderCommentary";
// import OrderCommentary from "pages/ios/orderCommentary";
// import AOrderAbsent from "pages/android/orderAbsent";
// import OrderAbsent from "pages/ios/orderAbsent";
// import AOrderCancel from "pages/android/orderCancel";
// import OrderCancel from "pages/ios/orderCancel";
// import VideoActive from "pages/android/videoActive";
// import VideoArmor from "pages/android/videoArmor";
// import VideoSupport from "pages/android/videoSupport";
// import VideoTrouble from "pages/android/videoTrouble";
// import VideoMoney from "pages/android/videoMoney";
// import VideoSettings from "pages/android/videoSettings";
// import VideoPackages from "pages/android/videoPackages";
// import VideoMenuSettings from "pages/android/videoMenuSettings";
// import VideoOrder from "pages/android/videoOrder";
// import VideoBalance from "pages/android/videoBalance";
// import VideoDriver from "pages/android/videoDriver";
// import VideoCkassaMenu from "pages/android/videoCkassaMenu";
// import VideoCkassaWithdraw from "pages/android/videoCkassaWithdraw";
// import VideoCkassaFuel from "pages/android/videoCkassaFuel";
// import VideoCkassaIdentification from "pages/android/videoCkassaIdentification";
// import VideoCkassaDuo from "pages/android/videoCkassaDuo";
// import QiwiMenu from "pages/android/qiwiMenu";
// import ARepQiwiWallet from "pages/android/repQiwiWallet";
import CustomRouter from "components/Router/Router";

function App() {
  const location = useLocation();
  document.title =
    rootStore.history[0] === "Обучение"
      ? "Обучение"
      : "Обучение | " + rootStore.history[0];
  return (
    <div className="App">
      { rootStore.languageID !== null &&
        <header className="header">
          {(location.pathname !== "/" &&
              location.pathname !== "/ios" &&
              location.pathname !== "/android" &&
              rootStore.history.length > 1 && (
                  <img
                      className="back-icon"
                      onClick={() => {
                        window.history.back();
                        rootStore.historyBack();
                      }}
                      src={rootStore.os == "ios" ? chevronBack : arrowBack}
                      alt=""
                  />
              )) || <div className="back-icon"></div>}
          <span className={`title title-android ${rootStore.languageID === "bn-BD" ? "title-bengali" : ""}`}>{rootStore.history[0]}</span>
          <div className="empty-header"></div>
        </header>
      }
      <CustomRouter></CustomRouter>
      {/*<Switch>*/}
      {/*  <Route exact path="/">*/}
      {/*    <Redirect to="/android"></Redirect>*/}
      {/*  </Route>*/}
      {/*  /!*ANDROID*!/*/}
      {/*  <Route exact path="/android">*/}
      {/*    <AIndex />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/balance/replenish">*/}
      {/*    <AReplenishMenu />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/settings/night">*/}
      {/*    <ANightMode />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/settings/font">*/}
      {/*    <AFontSize />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/balance/replenish/sberTerminal">*/}
      {/*    <ARepSberTerminal />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/balance/replenish/sberOnline">*/}
      {/*    <ARepSberOnline />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/balance/replenish/qiwi">*/}
      {/*    <QiwiMenu />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/balance/replenish/qiwi/terminal">*/}
      {/*    <ARepQiwi />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/balance/replenish/qiwi/wallet">*/}
      {/*    <ARepQiwiWallet />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/balance/replenish/ckassa">*/}
      {/*    <ARepCkassa />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/balance/replenish/card">*/}
      {/*    <ARepCard />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/balance/withdraw">*/}
      {/*    <AOutput />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/driver/state">*/}
      {/*    <ADriverState />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/driver/attribute">*/}
      {/*    <ADriverAttribute />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/driver/sort">*/}
      {/*    <ADriverSort />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/driver/preorder">*/}
      {/*    <ADriverPreorder />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/driver/armor">*/}
      {/*    <ADriverArmor />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/driver/package">*/}
      {/*    <ADriverPackages />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/driver/rating">*/}
      {/*    <ADriverRating />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/order/select">*/}
      {/*    <AOrderSelect />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/order/completition">*/}
      {/*    <AOrderComplete />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/order/auction">*/}
      {/*    <AOrderAuction />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/order/recalculation">*/}
      {/*    <AOrderRecalc />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/order/waiting">*/}
      {/*    <AOrderWaiting />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/order/agreement">*/}
      {/*    <AOrderAgreement />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/order/navigation">*/}
      {/*    <AOrderNavigation />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/order/commentary">*/}
      {/*    <AOrderCommentary />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/order/absent">*/}
      {/*    <AOrderAbsent />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/order/cancel">*/}
      {/*    <AOrderCancel />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/order/active">*/}
      {/*    <VideoActive />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/order">*/}
      {/*    <VideoOrder />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/order/armor">*/}
      {/*    <VideoArmor />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/support">*/}
      {/*    <VideoSupport />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/support/trouble">*/}
      {/*    <VideoTrouble />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/balance">*/}
      {/*    <VideoBalance />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/balance/operations">*/}
      {/*    <VideoMoney />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/app/settings">*/}
      {/*    <VideoSettings />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/app">*/}
      {/*    <VideoMenuSettings />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/driver">*/}
      {/*    <VideoDriver />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/driver/packages">*/}
      {/*    <VideoPackages />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/ckassa">*/}
      {/*    <VideoCkassaMenu />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/ckassa/withdraw">*/}
      {/*    <VideoCkassaWithdraw />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/ckassa/duo">*/}
      {/*    <VideoCkassaDuo />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/ckassa/identification">*/}
      {/*    <VideoCkassaIdentification />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/android/video/ckassa/fuel">*/}
      {/*    <VideoCkassaFuel />*/}
      {/*  </Route>*/}
      {/*  /!*IOS*!/*/}
      {/*  <Route exact path="/ios">*/}
      {/*    <Index />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/balance/replenish">*/}
      {/*    <ReplenishMenu />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/settings/night">*/}
      {/*    <NightMode />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/settings/font">*/}
      {/*    <FontSize />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/balance/replenish/sberTerminal">*/}
      {/*    <RepSberTerminal />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/balance/replenish/sberOnline">*/}
      {/*    <RepSberOnline />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/balance/replenish/qiwi">*/}
      {/*    <RepQiwi />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/balance/replenish/ckassa">*/}
      {/*    <RepCkassa />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/balance/replenish/card">*/}
      {/*    <RepCard />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/balance/withdraw">*/}
      {/*    <Output />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/driver/state">*/}
      {/*    <DriverState />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/driver/attribute">*/}
      {/*    <DriverAttribute />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/driver/sort">*/}
      {/*    <DriverSort />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/driver/preorder">*/}
      {/*    <DriverPreorder />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/driver/armor">*/}
      {/*    <DriverArmor />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/driver/package">*/}
      {/*    <DriverPackages />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/driver/rating">*/}
      {/*    <DriverRating />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/order/select">*/}
      {/*    <OrderSelect />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/order/completition">*/}
      {/*    <OrderComplete />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/order/auction">*/}
      {/*    <OrderAuction />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/order/recalculation">*/}
      {/*    <OrderRecalc />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/order/waiting">*/}
      {/*    <OrderWaiting />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/order/agreement">*/}
      {/*    <OrderAgreement />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/order/navigation">*/}
      {/*    <OrderNavigation />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/order/commentary">*/}
      {/*    <OrderCommentary />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/order/absent">*/}
      {/*    <OrderAbsent />*/}
      {/*  </Route>*/}
      {/*  <Route exact path="/ios/order/cancel">*/}
      {/*    <OrderCancel />*/}
      {/*  </Route>*/}
      {/*  /!*UNIFIED*!/*/}
      {/*  <Route exact path={"/" + rootStore.os + "/settings"}>*/}
      {/*    <Settings />*/}
      {/*  </Route>*/}
      {/*  <Route exact path={"/" + rootStore.os + "/driver"}>*/}
      {/*    <DriverMenu />*/}
      {/*  </Route>*/}
      {/*  <Route exact path={"/" + rootStore.os + "/balance"}>*/}
      {/*    <BalanceMenu />*/}
      {/*  </Route>*/}
      {/*  <Route exact path={"/" + rootStore.os + "/order"}>*/}
      {/*    <Order />*/}
      {/*  </Route>*/}
      {/*</Switch>*/}
    </div>
  );
}

export default App;
