const Languages = [
    {
        id: "ru-RU",
        locale: "russian",
        shortId: "ru",
        name: "Русский",
        localized: {
            english: "Russian",
            russian: "Русский",
            bengali: "রাশিয়ান",
        }
    },
    {
        id: "en-US",
        locale: "english",
        shortId: "eng",
        name: "English",
        localized: <Record<string, string>> {
            english: "English (United States)",
            russian: "Английский (США)",
            bengali: "ইংরেজি (মার্কিন যুক্তরাষ্ট্র)",
        }
    },
    {
        id: "bn-BD",
        locale: "bengali",
        shortId: "bn",
        name: "বাংলা",
        localized: {
            english: "Bengali",
            russian: "Бенгальский",
            bengali: "বাংলা",
        }
    }
]

interface ILocale {
    [key: string]: string;
}

export default Languages;
